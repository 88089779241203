/* Sticky Sidebar */
.sticky-sidebar {
    position: fixed;
    top: 55%;
    z-index: 9999;
}

.sidebar-list {
    list-style: none;
    padding-inline-start: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.sidebar-list .first {
    background-color: #05DCC4;
    color: black;
    cursor: pointer;
}

.sidebar-list .second {
    background-color: black;
    color: white;
}

.sidebar-list .third {
    background-color: #2db640;
    color: white;
}

.sidebar-list-item {
    font-size: larger;
    padding-inline: 0.4rem;
    padding-block: 0.3rem;
    border-radius: 0 5px 5px 0;
    width: 40px;
    height: 40px;
    transition: all 0.8s !important;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.sidebar-list-item a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.sidebar-icon i {
    font-size: 24px;
}

.text-expand {
    font-size: medium;
    display: none;
    opacity: 0;
    white-space: nowrap;
    transition: all 0.8s;
    padding-right: 1rem;
    padding-left: 0.5rem;
}


.text-expand.show {
    display: block;
    opacity: 1;
}

.sidebar-list-item.w:hover {
    max-width: 170px;
    width: 100%;
}

.sidebar-list-item.w:hover .text-expand {
    display: block;
    opacity: 1;
}

.sidebar-list-item.third>.text-expand.show {
    // display: block;
    max-width: 170px;
    width: 100%;
}

.sidebar-list-item.w-170 {
    max-width: 170px;
    width: 100%;
}

/* Sticky Sidebar */