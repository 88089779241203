section[data-content="connect-bg"] {
    position: relative;
    background-image: url(https://chakra.ancorathemes.com/wp-content/uploads/2020/12/header-bg-style-1-copyright.jpg);
    background-position: center center;
    background-size: cover;
    background-color: #00000048;
    background-blend-mode: color;
    height: 200px;

    @media screen and (min-width:768px) {
        height: 300px;
    }

    @media screen and (min-width:992px) {
        height: 350px;
    }

    h1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: xx-large;

        @media screen and (min-width:768px) {
            font-size: xxx-large;
        }
    }
}

section[data-content="contact-info"] {
    .info-container {
        .info-subtitle {
            font-size: small;
            margin-bottom: 1rem;
        }

        .info-list {
            list-style: none;
            padding-inline: 1rem;

            li:nth-child(1)::marker {
                content: "\F3E8";
                display: inline-block;
                font-family: bootstrap-icons !important;
                font-style: normal;
                font-weight: 400 !important;
                font-variant: normal;
                text-transform: none;
                text-align: center;
                line-height: 1;
                vertical-align: -.125em;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale
            }

            li:nth-child(2)::marker {
                content: "\F32F";
                display: inline-block;
                font-family: bootstrap-icons !important;
                font-style: normal;
                font-weight: 400 !important;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                vertical-align: -.125em;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale
            }

            li:nth-child(3)::marker {
                content: "\F4E7";
                display: inline-block;
                font-family: bootstrap-icons !important;
                font-style: normal;
                font-weight: 400 !important;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                vertical-align: -.125em;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale
            }

            li {
                padding-block-end: 0.5rem;
            }
        }
    }

    .contact-form {

        input,
        textarea {
            border-width: 0px 0px 1px;
            border-radius: 0px;

            &:focus {
                box-shadow: none;
            }
        }

        .name label::before {
            content: "\F4E1";
            display: inline-block;
            font-family: bootstrap-icons !important;
            font-weight: 400 !important;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            margin-inline-end: .5rem;
            vertical-align: -.125em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale
        }

        .email label::before {
            content: "\F84C";
            display: inline-block;
            font-family: bootstrap-icons !important;
            font-weight: 400 !important;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            margin-inline-end: .5rem;
            vertical-align: -.125em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale
        }

        .message label::before {
            content: "\F25C";
            display: inline-block;
            font-family: bootstrap-icons !important;
            font-weight: 400 !important;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            margin-inline-end: .5rem;
            vertical-align: -.125em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale
        }

        textarea {
            resize: none;
        }

        @media screen and (min-width:768px) {
            form {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                div {
                    margin-bottom: unset !important;
                }
            }
        }

        .error {
            color: red;
            font-size: small;
            position: absolute;
        }

        input.invalid,
        textarea.invalid {
            border-color: red;
        }
    }
}

section[data-content="whatwedo"] {

    .subtitle,
    .name,
    .designation {
        display: block;
        text-align: center;
    }

    .subtitle {
        font-size: small;
        margin-bottom: 1rem;
    }

    .para {
        text-align: center;
    }

    .typing {
        text-decoration: underline;
        color: #FF843C;
    }
}