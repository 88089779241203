section[data-content="retreats-bg"] {
    position: relative;
    background-image: url(https://chakra.ancorathemes.com/wp-content/uploads/2020/12/header-bg-style-2-copyright.jpg);
    background-position: center center;
    background-size: cover;
    background-color: #00000048;
    background-blend-mode: color;
    height: 200px;

    @media screen and (min-width:768px) {
        height: 300px;
    }

    @media screen and (min-width:992px) {
        height: 350px;
    }

    h1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: xx-large;

        @media screen and (min-width:768px) {
            font-size: xxx-large;
        }
    }
}

section[data-content="grid"] {
    .grid-container {
        display: flex;
        flex-wrap: wrap;
        .card-container{
            padding: 2rem;
        }

        .card {
            // padding: 2rem;
            border: 0;

            .card-body {
                padding-inline: 0;
                text-align: center;
                background-color: #C4FCF1;
                border-radius: 0px 0px 10px 10px;
                transition: all ease 0.7s;
                cursor: pointer;
            }

            .btn {
                position: relative;
                overflow: hidden;
            }

            .btn-text {
                position: absolute;
                text-indent: -110px;
                transition: all ease 0.7s;
                opacity: 0;
            }
            .btn-icon{
                transition: text-indent ease 0.7s;
            }

            .card-img-container {
                overflow: hidden;
                cursor: pointer;
                border-radius: 10px 10px 0 0;
            }
            img{
                transition: all ease 0.7s;
            }

            &:hover {
                .btn-text {
                    text-indent: 0;
                    opacity: 1;
                }

                .btn-icon {
                    text-indent: 100px;
                    // transition: all ease 0.7s;
                }

                img {
                    transform: scale(1.1);
                }

                .card-body {
                    background-color: #FFE8D2;
                }

                .btn-primary {
                    background-color: #FF843C;
                    border-color: #FF843C;
                }
            }
        }
    }
}


section[data-content="grid-images"] {
    .images-grid-container {
        position: relative;
    }


    .images-container {
        .img-cont {
            img {
                object-fit: cover;
                object-position: 100% 100%;
                transition: all 0.25s ease;
                &:hover {
                    filter: drop-shadow(2px 4px 6px black) brightness(0.9);
                }
            }
        }
    }

    .facebook-gallery {
        position: absolute;
        z-index: 10;
        top: 50% !important;
        bottom: 50% !important;
    }

    .facebook-gallery {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            background-color: rgba(255, 255, 255, 0.85);
            width: 6em;
            height: 6em;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            transition: all ease 0.5s;

            i {
                font-size: 2rem;
                color: black;
                transition: all ease 0.5s;
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.85);
            }

            &:hover i {
                color: white;
                transform: scale(1.25);
            }
        }
    }
}