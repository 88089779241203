.scroll__top {
    width: 40px;
    height: 40px;
    line-height: 40px;
	position: fixed;
	bottom: -10%;
	right: 1rem;
	font-size: 16px;
	border-radius: 4px;
	z-index: 99;
	color: black;
	text-align: center;
	cursor: pointer;
	background: #05DCC4 ;
	transition: 1s ease;
	border: none;
    &.open {
        bottom: 1rem;
    }
    &::after {
        position: absolute;
        z-index: -1;
        content: '';
        top: 100%;
        left: 5%;
        height: 10px;
        width: 90%;
        opacity: 1;
        background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
    }
    &:hover {
        background: #FF843C ;
        color:white;
    }
}