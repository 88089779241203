$navbar-toggler-focus-width: 0;

// $navbar-light-hover-color: #05DCC4 ;


.navbar-toggler-icon {
  display: none;
  /* Hide the default Bootstrap icon */
}

.navbar-toggler {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.icon-bar {
  background-color: #000;
  width: 25px;
  height: 3px;
  margin: 4px 0;
  transition: 0.4s;
}

.open .icon-bar:nth-child(1) {
  transform: rotate(-45deg) translate(-4px, 6px);
}

.open .icon-bar:nth-child(2) {
  opacity: 0;
}

.open .icon-bar:nth-child(3) {
  transform: rotate(45deg) translate(-4px, -6px);
}

.nav-link span {
  display: inline-block;
  padding-bottom: 2px;
  background: linear-gradient(#05DCC4, #05DCC4) center bottom;
  background-size: 0% 2px;
  background-repeat: no-repeat;
  transition: all 0.45s;
}

.nav-link:hover span {
  background-size: 100% 2px !important;
}