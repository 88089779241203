footer {
    .footer-container {
        display: grid;
        gap: 1rem;
        grid-template-areas:
            'map map'
            'address address'
            'email email'
            'whatsapp social'
            // 'social social'
        ;
        padding: 1rem;
    }

    span {
        display: block;
    }

    .footer--map {
        grid-area: map;
    }

    .footer--address {
        grid-area: address;
    }

    .footer--email {
        grid-area: email;
    }

    .footer--whatsapp {
        grid-area: whatsapp;
    }

    .footer--social {
        grid-area: social;
    }

    .footer-head {
        font-weight: bold;

    }
    span a:hover{
        color: #FF843C !important;
        transition: 0.3s color ease-in-out;
    }

    // .footer--whatsapp {
    //     display: flex;
    // }

    @media screen and (min-width:768px) {
        .footer-container {
            grid-template-areas:
                'map map map'
                'address email social'
                'address whatsapp social'
                // 'address social'
            ;
        }
    }
    @media screen and (min-width:992px) {
        .footer-container {
            grid-template-areas:
                'map address email social'
                'map address whatsapp social '
                // 'map address whatsapp '
            ;
            gap: 1rem 3rem;
            padding: 1rem 2rem;
        }
        .footer--map iframe{
            height: 220px !important;
        }
    }
}