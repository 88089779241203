section[data-content="free-meditation-bg"] {
    position: relative;
    background-image: url(https://www.filepicker.io/api/file/vYpACMAkQwKwH9Wb0vvi);
    background-position: center center;
    background-size: cover;
    background-color: #00000048;
    background-blend-mode: color;
    height: 200px;

    @media screen and (min-width:768px) {
        height: 300px;
    }

    @media screen and (min-width:992px) {
        height: 350px;
    }

    h1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: xx-large;

        @media screen and (min-width:768px) {
            font-size: xxx-large;
        }
    }
}

section[data-content="meditation-container"] {
    .sub-title {
        font-size: small;
    }

    p {
        text-align: justify;
    }

    .caps-t {
        color: black;
        font-size: 57px;
        height: 48px;
        line-height: 48px;
        margin: 0;
        text-align: left;
        width: auto !important;
        min-width: 60px;
        padding-right: 5px;
        overflow: visible;
        float: left;
        display: block;
    }

    .instructor {
        .instructor-img {
            object-position: 10;
        }

        margin-bottom: 1rem;
    }

    .description-one {
        grid-area: descOne;
    }

    .instructor {
        grid-area: instructor;
    }

    .description-two {
        grid-area: descTwo;
    }

    .description-three {
        grid-area: descThree;
    }

    .description-four {
        grid-area: descFour;
    }

    @media screen and (min-width:768px) {

        .sub-title,
        h2,
        .card-body,
        .card-text {
            text-align: center;
        }

        p {
            text-align: start;
        }

        .grid-container {
            display: grid;
            grid-template-areas:
                'instructor descOne'
                'instructor descTwo'
                'instructor descThree'
                'descFour descFour'
            ;


            gap: 1rem;
        }

        .instructor img {
            width: 200px;
        }
    }

    @media screen and (min-width:992px) {
        .grid-container {
            grid-template-areas:
                'instructor descOne'
                'instructor descTwo'
                'instructor descThree'
                'instructor descFour'
            ;
            align-items: center;
            column-gap: 3rem;
        }
    }

}