section[data-content='card'] {
    .card img {
        height: 250px !important;
        object-fit: cover;
    }

    .container {
        gap: 2rem;
    }
    .card a{
        text-decoration: none;
    }
}

@media screen and (min-width:768px) {
    section[data-content='card'] {
        .card img {
            height: 400px !important;
            object-fit: cover;
        }
    }
}

@media screen and (min-width:992px) {
    section[data-content='card'] {
        .card img {
            height: auto !important;
            object-fit: unset;
        }

        .card {
            width: 20rem;
        }
    }
}

@media screen and (min-width:1440px) {
    section[data-content='card'] {
        .card {
            width: 25rem;
        }
    }
}

section[data-content="start-here"] {
    a{
        text-decoration: none;
    }
}

.blockquote-footer::before {
    content: "" !important;
}

.material-symbols-outlined {
    font-variation-settings:
        'FILL' 1,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24
}

section[data-content="review"] {
    .carousel-indicators button {
        width: 10px !important;
        height: 10px !important;
        border-radius: 50% !important;
    }

    .blockquote {
        min-height: 270px;
    }
}

@media screen and (min-width:768px) {
    section[data-content="review"] {
        .blockquote {
            min-height: 160px;
        }
    }
}
@media screen and (min-width:1440px) {
    section[data-content="review"] {
        .blockquote {
            min-height: 120px;
        }
    }
}